<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="false"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav border-bottom">
          <div class="wizard-steps p-8 p-lg-10">
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label row">
                <i class="wizard-icon flaticon-book col"></i>
                <h3 class="wizard-title col">Información General</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label row">
                <i class="wizard-icon flaticon-piggy-bank col"></i>
                <h3 class="wizard-title col">Creditos Cliente</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label row">
                <i class="wizard-icon flaticon-network col"></i>
                <h3 class="wizard-title col">Usuario de Administración</h3>
              </div>
              <i class="wizard-arrow last flaticon2-next"></i>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav-->

        <!--begin: Wizard Body-->
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-11">
            <!--begin: Wizard Form-->
            <div class="form" id="kt_form">
              <!--begin: Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content">
                <h3 class="mb-10 font-weight-bold text-dark">
                  Información general
                </h3>
                <div class="form-group">
                  <label>Logo</label>
                  <div>
                    <div
                      class="image-input image-input-outline"
                      id="kt_profile_avatar"
                    >
                      <div
                        class="image-input-wrapper"
                        :style="{
                          backgroundImage: `url('${photo}')`,
                          backgroundPosition: 'center center'
                        }"
                      ></div>
                      <label
                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="change"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Change avatar"
                      >
                        <i class="fa fa-pen icon-sm text-muted"></i>
                        <input
                          type="file"
                          name="profile_avatar"
                          accept=".png, .jpg, .jpeg"
                          @change="onFileChange($event)"
                        />
                        <input type="hidden" name="profile_avatar_remove" />
                      </label>

                      <!-- <span
                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="remove"
                        data-toggle="tooltip"
                        title="Remove avatar"
                        @click="client.image = null"
                      >
                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                      </span> -->
                    </div>
                    <span class="form-text text-muted"
                      >Tipos permitidos: png, jpg, jpeg.</span
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label>Nombre del cliente</label>
                  <input
                    ref="client"
                    type="text"
                    name="client"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Nombre del cliente"
                    v-model="client.client"
                  />
                  <span class="form-text text-muted"
                    >Ingrese el nombre del cliente</span
                  >
                  <div
                    class="error text-primary"
                    v-if="!$v.client.client.required"
                  >
                    Este campo es obligatorio
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.client.client.minLength"
                  >
                    Son requeridos por lo menos 3 caracteres.
                  </div>
                </div>
                <div class="form-group">
                  <label>Nombre de la instancia</label>
                  <b-form-input
                    ref="name"
                    type="text"
                    name="name"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Nombre de la instancia"
                    aria-describedby="input-client-name"
                    v-model="client.name"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-client-name"
                    >This is a required field and must be at least 3
                    characters.</b-form-invalid-feedback
                  >
                  <span class="form-text text-muted"
                    >Ingrese el nombre de la instancia</span
                  >
                  <div
                    class="error text-primary"
                    v-if="!$v.client.name.required"
                  >
                    Este campo es obligatorio
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.client.name.minLength"
                  >
                    Son requeridos por lo menos 3 caracteres.
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.client.name.notExists && !$v.client.name.$pending"
                  >
                    El nombre de la instancia ya existe.
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <label>Descripción</label>
                      <textarea
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="description"
                        placeholder="Descripción del servicio"
                        v-model="client.description"
                      ></textarea>
                      <span class="form-text text-muted"
                        >Agrega una descripción para el servicio</span
                      >
                      <div
                        class="error text-primary"
                        v-if="!$v.client.description.required"
                      >
                        Este campo es obligatorio
                      </div>
                      <div
                        class="error text-primary"
                        v-if="!$v.client.description.minLength"
                      >
                        Son requeridos por lo menos 25 caracteres.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Subdominio de Instancia</label>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    name="subdomain"
                    id="subdomain"
                    placeholder="Subdominio de la Instancia"
                    autocomplete="off"
                    :disabled="client.id ? 'disabled' : false"
                    v-model="client.tenant"
                  />
                  <span class="form-text text-muted"
                    >Identificador de acceso a la instancia</span
                  >
                  <div
                    class="error text-primary"
                    v-if="!$v.client.tenant.required"
                  >
                    Este campo es obligatorio
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.client.tenant.minLength"
                  >
                    Son requeridos por lo menos 3 caracteres.
                  </div>
                  <div
                    class="error text-primary"
                    v-if="
                      !$v.client.tenant.notExists && !$v.client.tenant.$pending
                    "
                  >
                    El nombre de subdominio ya existe.
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.client.tenant.regex"
                  >
                    El subdominio solo puede contener caracteres válidos:
                    números, caracteres y guiones.
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 2-->

              <!--begin: Wizard Step 3-->
              <div class="pb-5" data-wizard-type="step-content">
                <div class="row">
                  <h4 class="mb-10 font-weight-bold text-dark col">
                    Creditos del cliente
                  </h4>
                  <h5
                    class="mb-10 font-weight-bold text-dark col text-right"
                    v-if="client.wallet"
                  >
                    <b>{{ client.wallet.credits | amount }}</b> creditos
                    disponibles
                  </h5>
                </div>
                <div class="form-group" v-if="!client.id">
                  <label>Creditos iniciales</label>
                  <div class="input-group input-group-lg input-group-solid">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="la">C</i>
                      </span>
                    </div>
                    <input
                      ref="initial-credits"
                      type="number"
                      name="initial-credits"
                      class="form-control form-control-lg form-control-solid"
                      placeholder="Creditos del cliente"
                      v-model="client.credits"
                      min="1"
                    />
                  </div>

                  <span class="form-text text-muted"
                    >Los creditos se depositarán al momento de crear al
                    cliente</span
                  >
                  <div
                    class="error text-primary"
                    v-if="!$v.client.credits.required"
                  >
                    Este campo es obligatorio
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.client.credits.greaterThanZero"
                  >
                    El valor ingresado debe ser mayor o igual a 0
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.client.credits.isInteger"
                  >
                    El valor ingresado debe ser entero
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 3-->

              <!--begin: Wizard Step 4-->
              <div class="pb-5 " data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Crear un usuario de administración
                </h4>
                <div>
                  <div class="form-group">
                    <label>Nombre completo</label>
                    <input
                      ref="name"
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      placeholder="Nombre y apellidos"
                      v-model="user.name"
                    />
                    <span class="form-text text-muted"
                      >Nombre del dueño de la cuenta</span
                    >
                    <div
                      class="error text-primary"
                      v-if="!$v.user.name.required"
                    >
                      Este campo es obligatorio
                    </div>
                    <div
                      class="error text-primary"
                      v-if="!$v.user.name.minLength"
                    >
                      Son requeridos por lo menos 5 caracteres.
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Alias</label>
                    <input
                      ref="nickname"
                      id="nickname"
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      placeholder="Nombre de usuario"
                      v-model="user.nickname"
                    />
                    <span class="form-text text-muted"
                      >Nombre alternativo para inicio de sesión</span
                    >
                    <div
                      class="error text-primary"
                      v-if="!$v.user.nickname.required"
                    >
                      Este campo es obligatorio.
                    </div>
                    <div
                      class="error text-primary"
                      v-if="!$v.user.nickname.minLength"
                    >
                      Son requeridos por lo menos 5 caracteres.
                    </div>
                    <div
                      class="error text-primary"
                      v-if="!$v.user.nickname.regex"
                    >
                      El alias no puede contener espacios ni caracteres
                      especiales.
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Correo Electrónico</label>
                    <div class="input-group input-group-lg input-group-solid">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="la la-at"></i>
                        </span>
                      </div>
                      <input
                        ref="email"
                        type="text"
                        class="form-control form-control-lg form-control-solid"
                        placeholder="Email"
                        v-model="user.email"
                      />
                    </div>
                    <span class="form-text text-muted"
                      >Correo electrónico para inicio de sesión</span
                    >
                    <div
                      class="error text-primary"
                      v-if="!$v.user.email.required"
                    >
                      Este campo es obligatorio
                    </div>
                    <div class="error text-primary" v-if="!$v.user.email.email">
                      Formato de email no válido
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Contraseña</label>
                    <input
                      ref="password"
                      class="form-control form-control-lg form-control-solid"
                      type="password"
                      placeholder="Contraseña"
                      v-model="user.password"
                    />
                    <div
                      class="error text-primary"
                      v-if="!$v.user.password.required"
                    >
                      Este campo es obligatorio
                    </div>
                    <div
                      class="error text-primary"
                      v-if="!$v.user.password.minLength"
                    >
                      Son requeridos por lo menos 8 caracteres.
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Repetir contraseña</label>
                    <input
                      ref="repeatPassword"
                      class="form-control form-control-lg form-control-solid"
                      type="password"
                      placeholder="Repetir la contraseña"
                      v-model="user.repeatPassword"
                    />
                    <div
                      class="error text-primary"
                      v-if="!$v.user.repeatPassword.sameAsPassword"
                    >
                      Las contraseñas no coinciden
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 4-->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2">
                  <button
                    class="btn btn-light-primary btn-lg"
                    data-wizard-type="action-prev"
                  >
                    Anterior
                  </button>
                </div>
                <div>
                  <button
                    v-on:click="submit"
                    ref="kt_submit_client"
                    class="btn btn-success btn-lg"
                    data-wizard-type="action-submit"
                  >
                    Finalizar
                  </button>
                  <button
                    class="btn btn-primary btn-lg"
                    data-wizard-type="action-next"
                    :disabled="!nextButtonValid"
                  >
                    Siguiente
                  </button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </div>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
    </div>
    <!--end: Wizard-->
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.wizard-label {
  display: flex;
  align-items: center;
}
.wizard-icon {
  font-size: 1.6em !important;
  margin: 0 !important;
  padding: 0 !important;
  max-width: 50px !important;
}
.wizard-title {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 1.2rem !important;
  text-align: left;
}
.wizard-steps {
  padding: 2rem 1rem;
}
</style>
<style v-if="client.id">
.wizard.wizard-1
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="done"]
  .edit.wizard-label
  .wizard-icon {
  color: #b5b5c3;
}
.wizard.wizard-1
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="done"]
  .edit.wizard-label
  .wizard-title {
  color: #7e8299;
}
</style>
<style scoped>
table th.hover {
  cursor: pointer;
}
table th.hover:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";

import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  email,
  sameAs,
  helpers
} from "vuelidate/lib/validators";

const RESOURCE = "tenancies";
const cv = {
  async greaterThanZero(value) {
    return value >= 0;
  },
  async isInteger(value) {
    return value % 1 == 0;
  },
  user: {
    alphaNumAndDotValidator: helpers.regex("alphaNumAndDot", /^[a-z0-9._-]*$/i)
  },
  tenant: {
    alphaNumAndDotValidator: helpers.regex("alphaNumAndDot", /^[a-z0-9-]*$/i),
    async subdomainNotExists(value) {
      if (!value) return true;
      var resource = await ApiService.query(RESOURCE, {
        filter: {
          limit: 1,
          where: { tenant: value }
        }
      });
      return resource.data.length == 0;
    },
    async nameNotExists(value) {
      if (!value) return true;
      var resource = await ApiService.query(RESOURCE, {
        filter: {
          limit: 1,
          where: { name: value }
        }
      });
      return resource.data.length == 0;
    }
  }
};
export default {
  mixins: [validationMixin],
  name: "ServiceWizard",
  data() {
    return {
      resource: RESOURCE,
      client: {
        image: null,
        wallet: {
          credits: 0,
          operations: []
        },
        client: "",
        name: "",
        description: "",
        tenant: "",
        credits: 1
      },
      user: {
        name: "",
        nickname: "",
        email: "",
        password: "",
        repeatPassword: ""
      },
      recharge: {
        credit: 0,
        comment: ""
      },
      default_photo: "media/users/blank.png",
      current_photo: null,
      currentPage: 1,
      rows: 0,
      perPage: 5,
      optionPerPage: [5, 10, 25, 50, 100],
      sortingList: {},
      wizard: {}
    };
  },
  validations: {
    client: {
      client: {
        required,
        minLength: minLength(3)
      },
      name: {
        required,
        minLength: minLength(3),
        notExists: cv.tenant.nameNotExists
      },
      description: {
        required,
        minLength: minLength(25)
      },
      tenant: {
        required,
        minLength: minLength(3),
        notExists: cv.tenant.subdomainNotExists,
        regex: cv.tenant.alphaNumAndDotValidator
      },
      credits: {
        required,
        greaterThanZero: cv.greaterThanZero,
        isInteger: cv.isInteger
      }
    },
    user: {
      name: {
        required,
        minLength: minLength(5)
      },
      nickname: {
        required,
        minLength: minLength(5),
        regex: cv.user.alphaNumAndDotValidator
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      },
      repeatPassword: {
        sameAsPassword: sameAs("password")
      }
    },

    step: {
      1: [
        "client.name",
        "client.client",
        "client.description",
        "client.tenant"
      ],
      2: ["client.credits"],
      3: [
        "user.name",
        "user.nickname",
        "user.email",
        "user.password",
        "user.repeatPassword"
      ]
    }
  },
  async mounted() {
    console.log(
      this.currentPage * (this.perPage - 1),
      this.currentPage * this.perPage
    );

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Clientes", route: "/clients" },
      { title: "Nuevo cliente" }
    ]);

    // Initialize form wizard
    this.wizard = new KTWizard("kt_wizard_v1", {
      startStep: 1, // initial active step number
      clickableSteps: false // allow step clicking
    });

    // Change event
    this.wizard.on("change", wizardObj => {
      console.log(
        "beforeNext",
        wizardObj.getStep(),
        wizardObj.getActionChange()
      );
      if (
        !this.isValidStep(wizardObj) &&
        wizardObj.getActionChange() == "next"
      ) {
        Swal.fire({
          title: "No es posible continuar",
          text: "Por favor revisa que los datos ingresados sean correctos",
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
        wizardObj.stop();
      } else {
        console.log("change");
        setTimeout(() => {
          KTUtil.scrollTop();
        }, 500);
      }
    });

    if (this.client.id) this.sortListFor("createdAt");
  },
  methods: {
    isValidStep(wizard) {
      if (this.$v.step[wizard.getStep()].$invalid) {
        return false;
      }

      return true;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    sortListFor(property) {
      if (!this.sortingList[property] || this.sortingList[property] == "DESC")
        this.sortingList[property] = "ASC";
      else this.sortingList[property] = "DESC";

      this.sortingList = {
        [property]: this.sortingList[property]
      };

      let compare = (a, b) => {
        if (a[property] < b[property]) {
          return this.sortingList[property] == "DESC" ? -1 : 1;
        }
        if (a[property] > b[property]) {
          return this.sortingList[property] == "DESC" ? 1 : -1;
        }
        return 0;
      };

      this.client.wallet.operations.sort(compare);
    },
    async onFileChange(e) {
      const file = e.target.files[0];

      if (!this.client.image) this.client.image = null;

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = event => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }

      let formData = new FormData();

      formData.append("image", file);

      let { data } = await ApiService.post(`file-upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });

      setTimeout(() => {
        this.client.image = data.s3Files[0].completePath;
        console.log(data.s3Files[0].completePath, this.client.image);
      }, 500);
    },
    formatDate(date) {
      console.log(date);
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    getEditor(ref) {
      return this.$refs[ref]
        .querySelector("iframe")
        .contentDocument.querySelector("body");
    },
    async getResource(id) {
      let { data } = await ApiService.get(`${this.resource}`, id);
      console.log({ ...data });
      return data;
    },
    async submit(e) {
      e.preventDefault();
      let client = {
        ...this.client,
        credits: parseInt(this.client.credits)
      };
      const submitButton = this.$refs["kt_submit_client"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.disabled = true;

      console.log(client);
      console.log("POST", { coreTenancy: client, tenantAdmin: this.user });
      try {
        let { data } = await ApiService.post(`${this.resource}`, {
          coreTenancy: client,
          tenantAdmin: this.user
        });
        console.log(data);
        this.client = data.coreTenancy;
        this.user = data.coreAdmin;
        window.location.href = "#/clients/" + data.coreTenancy.id;
        Swal.fire({
          title: "El cliente ha sido guardado exitosamente!",
          text: "",
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        }).then(function() {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          window.location.href = "#/clients";
        });
        //this.$forceUpdate();
      } catch (e) {
        console.log(e);
        Swal.fire({
          title: "El cliente no se ha sido creado",
          text: "Por favor revisa que los datos ingresados sean correctos",
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.disabled = false;
      }
    }
  },
  watch: {
    "client.tenant": {
      handler: function(value) {
        if (value) this.client.tenant = value.toLowerCase();
      },
      immediate: true
    },
    // 'client.credits':{
    //   handler: function(credits) {
    //     console.log(credits)
    //     // Do something here.
    //     if(credits < 1) this.client.credits = 1;
    //     else
    //     //this.client.credits = parseInt(credits)
    //   },
    //   immediate: true,
    // },
    "user.nickname": {
      handler: function(nickname) {
        // Do something here.
        this.user.nickname = nickname?.toLowerCase();
      },
      immediate: true
    },
    $route: {
      handler: function(to) {
        // Do something here.
        console.log(to);
        if (to.path == "/clients/new") {
          this.user = {};
          this.client = {};
        }

        this.$forceUpdate();
      },
      immediate: true
    }
  },
  computed: {
    nextButtonValid() {
      if (this.wizard)
        if (typeof this.wizard.getStep == "function") {
          let step = this.wizard.getStep();
          if (!this.$v.step[step]) return true;
          if (!this.$v.step[step]?.$invalid) return true;
        }
      return false;
    },
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    }
  }
};
</script>
