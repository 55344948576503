var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-custom" }, [
    _c("div", { staticClass: "card-body p-0" }, [
      _c(
        "div",
        {
          staticClass: "wizard wizard-1",
          attrs: {
            id: "kt_wizard_v1",
            "data-wizard-state": "step-first",
            "data-wizard-clickable": "false"
          }
        },
        [
          _vm._m(0),
          _c(
            "div",
            {
              staticClass:
                "row justify-content-center my-10 px-8 my-lg-15 px-lg-10"
            },
            [
              _c("div", { staticClass: "col-xl-12 col-xxl-11" }, [
                _c("div", { staticClass: "form", attrs: { id: "kt_form" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "pb-5",
                      attrs: { "data-wizard-type": "step-content" }
                    },
                    [
                      _c(
                        "h3",
                        { staticClass: "mb-10 font-weight-bold text-dark" },
                        [_vm._v(" Información general ")]
                      ),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Logo")]),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "image-input image-input-outline",
                              attrs: { id: "kt_profile_avatar" }
                            },
                            [
                              _c("div", {
                                staticClass: "image-input-wrapper",
                                style: {
                                  backgroundImage: "url('" + _vm.photo + "')",
                                  backgroundPosition: "center center"
                                }
                              }),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow",
                                  attrs: {
                                    "data-action": "change",
                                    "data-toggle": "tooltip",
                                    title: "",
                                    "data-original-title": "Change avatar"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-pen icon-sm text-muted"
                                  }),
                                  _c("input", {
                                    attrs: {
                                      type: "file",
                                      name: "profile_avatar",
                                      accept: ".png, .jpg, .jpeg"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.onFileChange($event)
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    attrs: {
                                      type: "hidden",
                                      name: "profile_avatar_remove"
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _c("span", { staticClass: "form-text text-muted" }, [
                            _vm._v("Tipos permitidos: png, jpg, jpeg.")
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Nombre del cliente")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.client.client,
                              expression: "client.client"
                            }
                          ],
                          ref: "client",
                          staticClass:
                            "form-control form-control-lg form-control-solid",
                          attrs: {
                            type: "text",
                            name: "client",
                            placeholder: "Nombre del cliente"
                          },
                          domProps: { value: _vm.client.client },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.client,
                                "client",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", { staticClass: "form-text text-muted" }, [
                          _vm._v("Ingrese el nombre del cliente")
                        ]),
                        !_vm.$v.client.client.required
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(" Este campo es obligatorio ")
                            ])
                          : _vm._e(),
                        !_vm.$v.client.client.minLength
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(
                                " Son requeridos por lo menos 3 caracteres. "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Nombre de la instancia")]),
                          _c("b-form-input", {
                            ref: "name",
                            staticClass:
                              "form-control form-control-lg form-control-solid",
                            attrs: {
                              type: "text",
                              name: "name",
                              placeholder: "Nombre de la instancia",
                              "aria-describedby": "input-client-name"
                            },
                            model: {
                              value: _vm.client.name,
                              callback: function($$v) {
                                _vm.$set(_vm.client, "name", $$v)
                              },
                              expression: "client.name"
                            }
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "input-client-name" } },
                            [
                              _vm._v(
                                "This is a required field and must be at least 3 characters."
                              )
                            ]
                          ),
                          _c("span", { staticClass: "form-text text-muted" }, [
                            _vm._v("Ingrese el nombre de la instancia")
                          ]),
                          !_vm.$v.client.name.required
                            ? _c("div", { staticClass: "error text-primary" }, [
                                _vm._v(" Este campo es obligatorio ")
                              ])
                            : _vm._e(),
                          !_vm.$v.client.name.minLength
                            ? _c("div", { staticClass: "error text-primary" }, [
                                _vm._v(
                                  " Son requeridos por lo menos 3 caracteres. "
                                )
                              ])
                            : _vm._e(),
                          !_vm.$v.client.name.notExists &&
                          !_vm.$v.client.name.$pending
                            ? _c("div", { staticClass: "error text-primary" }, [
                                _vm._v(" El nombre de la instancia ya existe. ")
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-xl-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [_vm._v("Descripción")]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.client.description,
                                  expression: "client.description"
                                }
                              ],
                              staticClass:
                                "form-control form-control-solid form-control-lg",
                              attrs: {
                                type: "text",
                                name: "description",
                                placeholder: "Descripción del servicio"
                              },
                              domProps: { value: _vm.client.description },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.client,
                                    "description",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c(
                              "span",
                              { staticClass: "form-text text-muted" },
                              [
                                _vm._v(
                                  "Agrega una descripción para el servicio"
                                )
                              ]
                            ),
                            !_vm.$v.client.description.required
                              ? _c(
                                  "div",
                                  { staticClass: "error text-primary" },
                                  [_vm._v(" Este campo es obligatorio ")]
                                )
                              : _vm._e(),
                            !_vm.$v.client.description.minLength
                              ? _c(
                                  "div",
                                  { staticClass: "error text-primary" },
                                  [
                                    _vm._v(
                                      " Son requeridos por lo menos 25 caracteres. "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Subdominio de Instancia")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.client.tenant,
                              expression: "client.tenant"
                            }
                          ],
                          staticClass:
                            "form-control form-control-solid form-control-lg",
                          attrs: {
                            type: "text",
                            name: "subdomain",
                            id: "subdomain",
                            placeholder: "Subdominio de la Instancia",
                            autocomplete: "off",
                            disabled: _vm.client.id ? "disabled" : false
                          },
                          domProps: { value: _vm.client.tenant },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.client,
                                "tenant",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", { staticClass: "form-text text-muted" }, [
                          _vm._v("Identificador de acceso a la instancia")
                        ]),
                        !_vm.$v.client.tenant.required
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(" Este campo es obligatorio ")
                            ])
                          : _vm._e(),
                        !_vm.$v.client.tenant.minLength
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(
                                " Son requeridos por lo menos 3 caracteres. "
                              )
                            ])
                          : _vm._e(),
                        !_vm.$v.client.tenant.notExists &&
                        !_vm.$v.client.tenant.$pending
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(" El nombre de subdominio ya existe. ")
                            ])
                          : _vm._e(),
                        !_vm.$v.client.tenant.regex
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(
                                " El subdominio solo puede contener caracteres válidos: números, caracteres y guiones. "
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "pb-5",
                      attrs: { "data-wizard-type": "step-content" }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "h4",
                          {
                            staticClass: "mb-10 font-weight-bold text-dark col"
                          },
                          [_vm._v(" Creditos del cliente ")]
                        ),
                        _vm.client.wallet
                          ? _c(
                              "h5",
                              {
                                staticClass:
                                  "mb-10 font-weight-bold text-dark col text-right"
                              },
                              [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("amount")(
                                        _vm.client.wallet.credits
                                      )
                                    )
                                  )
                                ]),
                                _vm._v(" creditos disponibles ")
                              ]
                            )
                          : _vm._e()
                      ]),
                      !_vm.client.id
                        ? _c("div", { staticClass: "form-group" }, [
                            _c("label", [_vm._v("Creditos iniciales")]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "input-group input-group-lg input-group-solid"
                              },
                              [
                                _vm._m(1),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.client.credits,
                                      expression: "client.credits"
                                    }
                                  ],
                                  ref: "initial-credits",
                                  staticClass:
                                    "form-control form-control-lg form-control-solid",
                                  attrs: {
                                    type: "number",
                                    name: "initial-credits",
                                    placeholder: "Creditos del cliente",
                                    min: "1"
                                  },
                                  domProps: { value: _vm.client.credits },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.client,
                                        "credits",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            ),
                            _c(
                              "span",
                              { staticClass: "form-text text-muted" },
                              [
                                _vm._v(
                                  "Los creditos se depositarán al momento de crear al cliente"
                                )
                              ]
                            ),
                            !_vm.$v.client.credits.required
                              ? _c(
                                  "div",
                                  { staticClass: "error text-primary" },
                                  [_vm._v(" Este campo es obligatorio ")]
                                )
                              : _vm._e(),
                            !_vm.$v.client.credits.greaterThanZero
                              ? _c(
                                  "div",
                                  { staticClass: "error text-primary" },
                                  [
                                    _vm._v(
                                      " El valor ingresado debe ser mayor o igual a 0 "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            !_vm.$v.client.credits.isInteger
                              ? _c(
                                  "div",
                                  { staticClass: "error text-primary" },
                                  [
                                    _vm._v(
                                      " El valor ingresado debe ser entero "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "pb-5 ",
                      attrs: { "data-wizard-type": "step-content" }
                    },
                    [
                      _c(
                        "h4",
                        { staticClass: "mb-10 font-weight-bold text-dark" },
                        [_vm._v(" Crear un usuario de administración ")]
                      ),
                      _c("div", [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Nombre completo")]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.user.name,
                                expression: "user.name"
                              }
                            ],
                            ref: "name",
                            staticClass:
                              "form-control form-control-lg form-control-solid",
                            attrs: {
                              type: "text",
                              placeholder: "Nombre y apellidos"
                            },
                            domProps: { value: _vm.user.name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.user, "name", $event.target.value)
                              }
                            }
                          }),
                          _c("span", { staticClass: "form-text text-muted" }, [
                            _vm._v("Nombre del dueño de la cuenta")
                          ]),
                          !_vm.$v.user.name.required
                            ? _c("div", { staticClass: "error text-primary" }, [
                                _vm._v(" Este campo es obligatorio ")
                              ])
                            : _vm._e(),
                          !_vm.$v.user.name.minLength
                            ? _c("div", { staticClass: "error text-primary" }, [
                                _vm._v(
                                  " Son requeridos por lo menos 5 caracteres. "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Alias")]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.user.nickname,
                                expression: "user.nickname"
                              }
                            ],
                            ref: "nickname",
                            staticClass:
                              "form-control form-control-lg form-control-solid",
                            attrs: {
                              id: "nickname",
                              type: "text",
                              placeholder: "Nombre de usuario"
                            },
                            domProps: { value: _vm.user.nickname },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.user,
                                  "nickname",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c("span", { staticClass: "form-text text-muted" }, [
                            _vm._v("Nombre alternativo para inicio de sesión")
                          ]),
                          !_vm.$v.user.nickname.required
                            ? _c("div", { staticClass: "error text-primary" }, [
                                _vm._v(" Este campo es obligatorio. ")
                              ])
                            : _vm._e(),
                          !_vm.$v.user.nickname.minLength
                            ? _c("div", { staticClass: "error text-primary" }, [
                                _vm._v(
                                  " Son requeridos por lo menos 5 caracteres. "
                                )
                              ])
                            : _vm._e(),
                          !_vm.$v.user.nickname.regex
                            ? _c("div", { staticClass: "error text-primary" }, [
                                _vm._v(
                                  " El alias no puede contener espacios ni caracteres especiales. "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Correo Electrónico")]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "input-group input-group-lg input-group-solid"
                            },
                            [
                              _vm._m(2),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.email,
                                    expression: "user.email"
                                  }
                                ],
                                ref: "email",
                                staticClass:
                                  "form-control form-control-lg form-control-solid",
                                attrs: { type: "text", placeholder: "Email" },
                                domProps: { value: _vm.user.email },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.user,
                                      "email",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _c("span", { staticClass: "form-text text-muted" }, [
                            _vm._v("Correo electrónico para inicio de sesión")
                          ]),
                          !_vm.$v.user.email.required
                            ? _c("div", { staticClass: "error text-primary" }, [
                                _vm._v(" Este campo es obligatorio ")
                              ])
                            : _vm._e(),
                          !_vm.$v.user.email.email
                            ? _c("div", { staticClass: "error text-primary" }, [
                                _vm._v(" Formato de email no válido ")
                              ])
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Contraseña")]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.user.password,
                                expression: "user.password"
                              }
                            ],
                            ref: "password",
                            staticClass:
                              "form-control form-control-lg form-control-solid",
                            attrs: {
                              type: "password",
                              placeholder: "Contraseña"
                            },
                            domProps: { value: _vm.user.password },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.user,
                                  "password",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          !_vm.$v.user.password.required
                            ? _c("div", { staticClass: "error text-primary" }, [
                                _vm._v(" Este campo es obligatorio ")
                              ])
                            : _vm._e(),
                          !_vm.$v.user.password.minLength
                            ? _c("div", { staticClass: "error text-primary" }, [
                                _vm._v(
                                  " Son requeridos por lo menos 8 caracteres. "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Repetir contraseña")]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.user.repeatPassword,
                                expression: "user.repeatPassword"
                              }
                            ],
                            ref: "repeatPassword",
                            staticClass:
                              "form-control form-control-lg form-control-solid",
                            attrs: {
                              type: "password",
                              placeholder: "Repetir la contraseña"
                            },
                            domProps: { value: _vm.user.repeatPassword },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.user,
                                  "repeatPassword",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          !_vm.$v.user.repeatPassword.sameAsPassword
                            ? _c("div", { staticClass: "error text-primary" }, [
                                _vm._v(" Las contraseñas no coinciden ")
                              ])
                            : _vm._e()
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between border-top pt-10"
                    },
                    [
                      _vm._m(3),
                      _c("div", [
                        _c(
                          "button",
                          {
                            ref: "kt_submit_client",
                            staticClass: "btn btn-success btn-lg",
                            attrs: { "data-wizard-type": "action-submit" },
                            on: { click: _vm.submit }
                          },
                          [_vm._v(" Finalizar ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary btn-lg",
                            attrs: {
                              "data-wizard-type": "action-next",
                              disabled: !_vm.nextButtonValid
                            }
                          },
                          [_vm._v(" Siguiente ")]
                        )
                      ])
                    ]
                  )
                ])
              ])
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wizard-nav border-bottom" }, [
      _c("div", { staticClass: "wizard-steps p-8 p-lg-10" }, [
        _c(
          "div",
          { staticClass: "wizard-step", attrs: { "data-wizard-type": "step" } },
          [
            _c("div", { staticClass: "wizard-label row" }, [
              _c("i", { staticClass: "wizard-icon flaticon-book col" }),
              _c("h3", { staticClass: "wizard-title col" }, [
                _vm._v("Información General")
              ])
            ]),
            _c("i", { staticClass: "wizard-arrow flaticon2-next" })
          ]
        ),
        _c(
          "div",
          { staticClass: "wizard-step", attrs: { "data-wizard-type": "step" } },
          [
            _c("div", { staticClass: "wizard-label row" }, [
              _c("i", { staticClass: "wizard-icon flaticon-piggy-bank col" }),
              _c("h3", { staticClass: "wizard-title col" }, [
                _vm._v("Creditos Cliente")
              ])
            ]),
            _c("i", { staticClass: "wizard-arrow flaticon2-next" })
          ]
        ),
        _c(
          "div",
          { staticClass: "wizard-step", attrs: { "data-wizard-type": "step" } },
          [
            _c("div", { staticClass: "wizard-label row" }, [
              _c("i", { staticClass: "wizard-icon flaticon-network col" }),
              _c("h3", { staticClass: "wizard-title col" }, [
                _vm._v("Usuario de Administración")
              ])
            ]),
            _c("i", { staticClass: "wizard-arrow last flaticon2-next" })
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "la" }, [_vm._v("C")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "la la-at" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mr-2" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-light-primary btn-lg",
          attrs: { "data-wizard-type": "action-prev" }
        },
        [_vm._v(" Anterior ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }